import React from "react"
import Slideshow from "../components/bb/slideshow"
import Table from "../components/bb/table"
import { css } from "@emotion/react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/bb/button"
// import { Link } from "gatsby"

let amenities = [
  ["Bathtub","Bed linens", "Room-darkening shades"],
  ["Indoor fireplace", "Ceiling fan", "Central Heating"],
  ["External security cameras on property", "Fire extinguisher", "Smoke alarm"],
  ["Dishes", "Silverware", "Kitchen"],
  ["Coffee maker", "27in. HDTV with Roku", "Wifi – 25 Mbps"],
  ["Private entrance", "BBQ grill", "Outdoor dining area"],
  ["Free parking on site", "Long term stays allowed", "Building Staff"],
];
// append green checkmark infront of each
amenities = amenities.map((el) =>{
  return el.map((e)=>{
    return (
    <div>
      <span css={css`
        color: var(--secondary);
        font-weight: bolder;
        text-shadow: rgba(var(--secondaryalt), 0.75) 1px 2px 4px;
      `}>✔ </span>
      {e}
    </div>)
  })
});

export default function CabinThree() {

  const bookButton = (
    <Button
      value="Book on Airbnb"
      href="https://www.airbnb.com/rooms/50137014?#availability-calendar"
      customstyle='padding: 24px 36px; margin: 24px 0;'
    />
  );

  return(
    <Layout>
    <SEO title="The Relaxation Cabin" />
      <h1>Relaxation Cabin: 1 bedroom with cozy fireplace</h1>
            
      <Slideshow imagefilter='cabin4' />
      
      {bookButton}
      
      <div id="section1">
        <h2>Property Description</h2>

        <p>Forget your worries in this spacious and serene 1 bedroom, fully remodeled "Relaxation" cabin. Has a beautiful LP fireplace with custom glass tile surround, hardwood flooring and steps away from Crooked Creek which meanders through the property.</p>
        
        <h3>Sleeping arrangements</h3>
        <p>2 guests · 1 bedrooms · 1 beds · 1 bath</p>

        <h3>Other things to note</h3>
        <p>Guests to bring personal towels and soaps. Sheets and bedding provided and cleaned before and after stay.
           Check out is 11 am to allow time to clean for next guests arriving.
        </p>
      </div>
      <div id="amenities">
        <h2>amenities</h2>
        <Table data={amenities} />
      </div>

      {bookButton}
      
  </Layout>   
  )
}
